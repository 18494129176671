<template>
  <v-row no-gutters dense ref="menu" class="menu">
    <v-col cols="12" v-if="isMobile">
      <div class="close">
        <v-btn icon @click="closeDrawer">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="logo">
        <div class="logo-text text-left">
          <router-link
            :to="{ name: 'HomeView', hash: '' }"
            class="secondary--text"
          >
            <h2>G.O.D</h2>
          </router-link>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="menu-items d-block d-lg-flex d-md-flex">
        <div class="menu-text">
          <router-link
            exact
            :to="{ name: 'HomeView', hash: '#about-us' }"
            class="secondary--text"
            >About Us
          </router-link>
        </div>

        <div class="menu-text">
          <router-link
            exact
            :to="{ name: 'HomeView', hash: '#what-we-host' }"
            class="secondary--text"
            >What We Host
          </router-link>
        </div>

        <div class="menu-text">
          <router-link
            exact
            :to="{ name: 'HomeView', hash: '#testimonial' }"
            class="secondary--text"
            >Testimonial
          </router-link>
        </div>

        <div class="menu-text">
          <router-link
            exact
            :to="{ name: 'HomeView', hash: '#contact-us' }"
            class="secondary--text"
            >Contact us
          </router-link>
        </div>

        <div class="menu-text">
          <v-btn
            color="green"
            exact
            :to="{ name: 'BookNow' }"
            class="primary--text"
          >
            Book Now
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AppMenu",
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer");
    },
  },
};
</script>
<style scoped>
.menu .menu-items {
  justify-content: end;
  align-items: center;
}

.menu .menu-text {
  text-align: left;
}

.menu .menu-text a {
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: normal;
}
.menu .menu-text a.v-btn--active {
  background: #9ab291 !important;
}
.menu .menu-text + .menu-text {
  margin-left: 30px;
}

@media only screen and (max-width: 960px) {
  .menu {
    position: relative;
    padding: 80px 0 80px 30px;
  }

  .menu .close {
    position: absolute;
    top: 5%;
    left: 90%;
    transform: translate(-90%, -5%);
  }

  .menu .logo .logo-text {
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .menu .logo-text a {
    font-weight: 600;
    text-decoration: none;
  }

  .menu .menu-text + .menu-text {
    margin-left: 0;
    margin-top: 25px;
  }

  .menu .menu-text a {
    text-decoration: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: normal;
  }
}
</style>
