import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
// import UnderMaintenance from "../views/UnderMaintainance";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    // component: UnderMaintenance,
    component: HomeView,
  },
  {
    path: "/book-now",
    name: "BookNow",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (booknow.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "booknow" */ "../views/BookNow.vue"),
  },
  {
    path: "/book-online/:location",
    name: "BookOnline",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (bookonline.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bookonline" */ "../views/BookOnline.vue"),
  },
  // {
  //   path: "/book-online",
  //   name: "BookOnline",
  //   props: true,
  //   // route level code-splitting
  //   // this generates a separate chunk (bookonline.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "bookonline" */ "../views/UnderMaintainance.vue"),
  // },
  {
    path: "/booking-details/:location/:planid/:slotid",
    name: "BookingDetails",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (bookingdetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bookingdetails" */ "../views/BookingDetails.vue"
      ),
  },
  {
    path: "/policy",
    name: "PolicyView",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (policy.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "policy" */ "../views/PolicyView.vue"),
  },
  {
    path: "/booking/:id",
    name: "booking",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (booking.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookingView.vue"),
  },
  {
    path: "/bookingresponse/:id",
    name: "bookingresponse",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (booking.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/BookingView.vue"),
  },
  {
    path: "/*",
    name: "PageNotFound",
    // route level code-splitting
    // this generates a separate chunk (pagenotfound.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "pagenotfound" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/",
});

export default router;
