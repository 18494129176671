<template>
  <v-container fluid class="">
    <v-row no-gutters class="">
      <v-col cols="4" lg="4" md="2" align-self="center">
        <div class="logo">
          <div class="logo-text text-left">
            <router-link :to="{ name: 'HomeView' }" class="secondary--text">
              <h2>G.O.D</h2>
            </router-link>
          </div>
        </div>
      </v-col>
      <v-col
        cols="8"
        lg="8"
        md="10"
        align-self="center"
        class="d-none d-lg-block d-md-block"
      >
        <app-menu @closeDrawer="drawer = false"></app-menu>
      </v-col>
      <v-col cols="8" class="d-block d-lg-none d-md-none text-right">
        <div class="menu-text">
          <v-btn
            class="primary--text"
            color="secondary"
            exact
            :to="{ name: 'BookNow' }"
          >
            Book Now
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppMenu from "@/components/Menus.vue";

export default {
  name: "AppHeader",
  components: {
    AppMenu,
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.header .logo .logo-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.header .logo-text a {
  font-weight: 600;
  text-decoration: none;
}
</style>
