<template>
  <v-container>
    <v-row class="justify-space-around">
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="4"
        v-for="location in locations"
        :key="location.id"
      >
        <v-card
          class="pa-lg-5 pa-md-5 pa-sm-3 d-flex justify-center align-center"
          :class="`${textColor}--text`"
          :color="color"
          min-height="200"
          @click="onLocationClick(location)"
        >
          <div>
            <b>{{ location.name }}</b>
            <div><small>Click to view Slots</small></div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getAllLocations } from "@/services/booking/booking.service";

export default {
  name: "AllLocations",
  data() {
    return { locations: [] };
  },
  mounted() {
    this.getAllLocations();
  },
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    textColor: {
      type: String,
      default: "primary",
    },
  },
  methods: {
    getAllLocations() {
      getAllLocations()
        .then((response) => {
          this.locations = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onLocationClick(location) {
      this.$store.commit("setSelectedLocation", location);
      await this.$nextTick();
      this.$router.push({
        name: "BookOnline",
        params: { location: location.name.toLowerCase() },
      });
    },
  },
};
</script>
