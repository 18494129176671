<template>
  <v-row no-gutters ref="home" class="home" justify="center">
    <v-col cols="12">
      <section id="banner-video" class="black">
        <div class="banner-video">
          <img
            :src="bannerVideoMobile"
            alt="banner-video"
            width="100%"
            height="auto"
            class="mx-auto d-block d-sm-block d-md-none d-lg-none"
          />
          <img
            :src="bannerVideo"
            alt="banner-video"
            width="100%"
            height="auto"
            class="mx-auto d-none d-sm-none d-md-block d-lg-block"
          />
          <div class="banner-text text-left"></div>
          <!-- <div class="banner-button text-center">
            <v-btn
              small
              dark
              fab
              color="secondary"
              class="bounce"
              @click="scrollTo('#about-us')"
            >
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </v-btn>
          </div> -->
        </div>
      </section>
    </v-col>

    <v-col cols="12">
      <section id="about-us" class="about-us secondary">
        <v-container>
          <v-card height="" color="primary" class="pa-5">
            <v-row no-gutters class="fill-height" justify="center">
              <v-col cols="12" lg="7" md="7" sm="7">
                <v-card
                  class="pa-lg-5 pa-md-5 pa-sm-3 secondary--text"
                  color="primary"
                  flat
                >
                  <!-- <div class="about-us-title secondary--text">ABOUT US</div> -->
                  <div class="about-us-heading secondary--text">
                    Bangalore's 1st and biggest celebration cafe.
                  </div>
                  <div class="about-us-description">
                    The only celebration cafe to enrich and cherish GOD
                    experience!
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12">
                <div class="locations">
                  <all-locations></all-locations>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </section>
    </v-col>

    <v-col cols="12" lg="8" md="10">
      <section id="what-we-host" class="what-we-host primary">
        <v-container fluid>
          <v-row class="justify-center">
            <v-col cols="12">
              <div class="what-we-host-title secondary--text">What we Host</div>
              <div class="what-we-host-heading secondary--text">
                Joyful experiences for you and your family
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <div class="px-3 pb-6">
                <v-card
                  color="secondary"
                  raised
                  tile
                  style="background-size: cover; background-position: center"
                  :style="{ 'background-image': `url(${what_we_host.image2})` }"
                  min-height="400"
                ></v-card>
                <v-card color="secondary" hover tile min-height="200">
                  <v-row no-gutters justify="center" class="fill-height">
                    <v-col cols="12" align-self="center" class="text-left">
                      <div class="service-content primary--text">
                        <div class="service-title">GOD's Love</div>
                        <div class="service-description mb-5">
                          A charming rooftop hideaway adorned with a 6ft red
                          rose ring, artificial grass surroundings, customizable
                          neon lights for various occasions.
                        </div>
                        <div class="text-center">
                          <v-btn
                            color="primary"
                            class="secondary--text"
                            :to="{
                              name: 'BookOnline',
                              params: { selectedPlanId: 1 },
                            }"
                          >
                            Book now at ₹399
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <div class="px-3 pb-6">
                <v-card
                  color="secondary"
                  raised
                  tile
                  style="background-size: cover; background-position: center"
                  :style="{ 'background-image': `url(${what_we_host.image3})` }"
                  min-height="400"
                >
                </v-card>
                <v-card color="secondary" hover tile min-height="200">
                  <v-row no-gutters justify="center" class="fill-height">
                    <v-col cols="12" align-self="center" class="text-left">
                      <div class="service-content primary--text">
                        <div class="service-title">GOD's Magic</div>
                        <div class="service-description mb-5">
                          An exclusive rooftop retreat featuring a Cuban-themed
                          tent with ambient lighting, surrounded by artificial
                          grass, and customizable neon lights.
                        </div>
                        <div class="text-center">
                          <v-btn
                            color="primary"
                            class="secondary--text"
                            :to="{
                              name: 'BookOnline',
                              params: { selectedPlanId: 2 },
                            }"
                          >
                            Book Now at ₹499
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <div class="px-3 pb-6">
                <v-card
                  color="secondary"
                  raised
                  tile
                  style="background-size: cover; background-position: center"
                  :style="{ 'background-image': `url(${what_we_host.image4})` }"
                  min-height="400"
                >
                </v-card>
                <v-card color="secondary" hover tile min-height="200">
                  <v-row no-gutters justify="center" class="fill-height">
                    <v-col cols="12" align-self="center" class="text-left">
                      <div class="service-content primary--text">
                        <div class="service-title">Dream's Studio</div>
                        <div class="service-description mb-5">
                          A luxurious, private space adorned with artificial
                          green leaf panels, a stunning 6ft heart-shaped display
                          of artificial red roses, customizable neon lighting,
                          and a well-equipped table with props, complemented by
                          wall lighting. This premium setting, ideal for small
                          groups of up to 6 people, includes AC, a 2K projector
                          with an HD screen and OTT apps, as well as comfortable
                          sofa seating for a perfect gathering.
                        </div>
                        <div class="text-center">
                          <v-btn
                            color="primary"
                            class="secondary--text"
                            :to="{
                              name: 'BookOnline',
                              params: { selectedPlanId: 2 },
                            }"
                          >
                            Book Now at ₹799
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="6">
              <div class="px-3 pb-6">
                <v-card
                  color="secondary"
                  raised
                  tile
                  style="background-size: cover; background-position: center"
                  :style="{ 'background-image': `url(${what_we_host.image6})` }"
                  min-height="400"
                >
                </v-card>
                <v-card color="secondary" hover tile min-height="200">
                  <v-row no-gutters justify="center" class="fill-height">
                    <v-col cols="12" align-self="center" class="text-left">
                      <div class="service-content primary--text">
                        <div class="service-title">Gold Studio</div>
                        <div class="service-description mb-5">
                          A lavish private space featuring elegant gold wall
                          panels, festive balloon decorations, customizable neon
                          lighting, a well-furnished table with props, and
                          ambient wall lighting. This premium setting, catering
                          to gatherings of up to 8 people, offers AC comfort, a
                          2K projector with HD screen and pre-installed OTT
                          apps, comfortable sofa seating, and complete privacy,
                          making it the ideal choice for gatherings with friends
                          and family.
                        </div>
                        <div class="text-center">
                          <v-btn
                            color="primary"
                            class="secondary--text"
                            :to="{
                              name: 'BookOnline',
                              params: { selectedPlanId: 2 },
                            }"
                          >
                            Book Now at ₹999
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="">
                <v-btn
                  color="green"
                  exact
                  :to="{ name: 'BookNow' }"
                  class="primary--text"
                  x-large
                >
                  view all
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-col>

    <v-col cols="12">
      <section id="testimonial" class="testimonial secondary">
        <v-container fluid>
          <v-row justify-content="space-around">
            <v-col
              cols="12"
              lg="10"
              md="10"
              offset-lg="2"
              offset-md="2"
              class="text-left"
            >
              <div class="testimonial-title primary--text">Testimonials</div>
              <div class="testimonial-heading">
                Hear what our past guests have to say
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              offset-lg="2"
              offset-md="2"
              class="text-left"
            >
              <v-carousel
                height="auto"
                hide-delimiter-background
                delimiter-icon="mdi-minus"
                :cycle="true"
              >
                <v-carousel-item v-for="(image, i) in about" :key="i">
                  <v-card
                    color="lightpink"
                    min-height="500"
                    style="background-size: cover"
                    :style="{ 'background-image': `url(${image})` }"
                  >
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="6">
              <v-carousel
                height="auto"
                color="secondary"
                class=""
                hide-delimiter-background
                :show-arrows="false"
                delimiter-icon="mdi-minus"
                :cycle="true"
              >
                <v-carousel-item>
                  <v-sheet color="secondary" height="100%">
                    <v-row no-gutters justify="center">
                      <v-col
                        cols="11"
                        lg="8"
                        md="9"
                        class="text-center primary--text"
                      >
                        <div class="testimonial">
                          <v-icon size="50" color="primary">
                            mdi-format-quote-close
                          </v-icon>
                          <div class="description primary--text">
                            <i>
                              Thank you GOD, this was one of a Kind and
                              Hospitable property owner. Very nicely and elegant
                              arrangements on time, Execution was spot on with
                              timely addressed queries. If you are looking for a
                              best destination to celebrate your special
                              occasions, this should be the place...
                            </i>
                          </div>
                          <div class="name text-right mt-5 primary--text">
                            <i>
                              <v-icon size="25" color="primary"
                                >mdi-minus</v-icon
                              >
                              Google
                            </i>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item>
                  <v-sheet color="secondary" height="100%">
                    <v-row no-gutters justify="center" class="fill-height">
                      <v-col
                        cols="11"
                        lg="8"
                        md="8"
                        align-self="center"
                        class="text-center primary--text"
                      >
                        <div class="testimonial">
                          <v-icon size="50" color="primary">
                            mdi-format-quote-close
                          </v-icon>
                          <div class="description primary--text">
                            <i>
                              We had planed for our friend birthday. And this
                              location is very good for celebration where the
                              decoration was very simple and cute and we spent
                              our day well. Thank you so much Gardens of Dreams.
                            </i>
                          </div>
                          <div class="name text-right mt-5 primary--text">
                            <i>
                              <v-icon size="25" color="primary"
                                >mdi-minus</v-icon
                              >
                              Google
                            </i>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item>
                  <v-sheet color="secondary" height="100%">
                    <v-row no-gutters justify="center" class="fill-height">
                      <v-col
                        cols="11"
                        lg="8"
                        md="8"
                        align-self="center"
                        class="text-center primary--text"
                      >
                        <div class="testimonial">
                          <v-icon size="50" color="primary">
                            mdi-format-quote-close
                          </v-icon>
                          <div class="description primary--text">
                            <i>
                              Very nice place to celebrate birthdays and
                              anniversary.. Friendly staff with nice
                              decorations.. Place is suitable for couples and if
                              you are of 4 to 5 people group...
                            </i>
                          </div>
                          <div class="name text-right mt-5 black--text">
                            <i>
                              <v-icon size="25" color="primary">
                                mdi-minus
                              </v-icon>
                              Google
                            </i>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-col>

    <v-col cols="12">
      <section id="contact-us" class="contact-us secondary">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12">
              <div class="contact-us-heading primary--text">Contact Us</div>
            </v-col>

            <v-col cols="12">
              <contact-form></contact-form>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/ContactForm.vue";
import AllLocations from "@/components/AllLocations.vue";

export default {
  name: "HomeView",
  components: {
    ContactForm,
    AllLocations,
  },
  mounted() {
    if (this.$route.hash) {
      this.$vuetify.goTo(this.$route.hash, {
        duration: 750,
        offset: 0,
      });
    } else {
      this.$vuetify.goTo(0, {
        duration: 750,
        offset: 0,
      });
    }
  },
  watch: {
    "$route.hash": {
      handler: function (val) {
        if (val) {
          this.$vuetify.goTo(val, { duration: 1000, offset: 0 });
        } else {
          this.$vuetify.goTo(0, {
            duration: 1000,
            offset: 0,
          });
        }
      },
    },
  },
  data() {
    return {
      // bannerVideoMp4: require("@/assets/video/bannerVideo.mp4"),
      // bannerVideoWebm: require("@/assets/video/bannerVideo.webm"),
      bannerVideo: require("@/assets/video/bannerVideo.gif"),
      bannerVideoMobile: require("@/assets/video/bannerVideoMobile.gif"),
      banner_image: require("@/assets/banner_image.jpg"),
      lazysrc: require("@/assets/gallery/1.jpg"),
      about: {
        // image1: require("@/assets/gallery/god_galaxy/2.jpeg"),
        image2: require("@/assets/gallery/rajajinagar/god_love/2.jpeg"),
        image3: require("@/assets/gallery/rajajinagar/god_magic/1.jpeg"),
        image4: require("@/assets/gallery/rajajinagar/god_magic/2.jpeg"),
        image6: require("@/assets/gallery/gold_studio/2.jpg"),
      },
      what_we_host: {
        // image1: require("@/assets/gallery/god_galaxy/2.jpeg"),
        image2: require("@/assets/gallery/rajajinagar/god_love/2.jpeg"),
        image3: require("@/assets/gallery/rajajinagar/god_magic/1.jpeg"),
        image4: require("@/assets/gallery/rajajinagar/god_magic/2.jpeg"),
        image6: require("@/assets/gallery/gold_studio/2.jpg"),

        // image4: require("@/assets/gallery/family_studio/3.jpg"),
      },
      reviews: require("@/assets/reviews.jpg"),
    };
  },
  methods: {
    scrollTo(id) {
      this.$router.push({ name: "HomeView", hash: id });
    },
  },
};
</script>
<style scoped>
.home .banner-video {
  position: relative;
  height: calc(100vh - 64px);
  width: 100%;
}

.home .banner-video .banner-text {
  color: white;
  font-size: 50px;
  font-weight: 700;
  position: absolute;
  top: 70%;
  left: 15%;
  transform: translate(-15%, -70%);
}

/* .home .banner-video .banner-button {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -95%);
} */

.home .banner-video .banner-text h1 {
  line-height: 1;
}

.home .banner-video .banner-text h3 {
  margin-left: 10px;
}

.home .banner-image {
  height: calc(100vh - 64px);
  width: 100%;
}

.home .about-us {
  margin-top: -150px;
  padding-bottom: 100px;
}
.home .about-us .locations {
  padding-bottom: 50px;
}
.home .what-we-host,
.home .testimonial,
.home .contact-us,
.home .location {
  padding: 100px 0;
}

.home .about-us .about-us-title,
.home .what-we-host .what-we-host-title,
.home .testimonial .testimonial-title,
.home .contact-us .contact-us-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 4px;
}

.home .about-us .about-us-heading {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 25px;
}
.home .what-we-host .what-we-host-heading,
.home .testimonial .testimonial-heading,
.home .contact-us .contact-us-heading {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 50px;
}

.home .about-us .about-us-description {
  /* color: #636a76; */
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
}

.home .about-us .about-us-image {
  height: 250px;
  background-position: center;
}

.home .about-us .about-us-image.image3 {
  height: 508px;
}

.home .testimonial .review-image {
  background-position: top;
}

.home .testimonial .review-image,
.home .about-us .about-us-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.home .what-we-host .service-content {
  padding: 24px;
}

.home .what-we-host .service-title {
  font-size: 22px;
  letter-spacing: 1.2px;
  font-weight: 500;
  margin-bottom: 10px;
}

.home .what-we-host .service-description {
  font-size: 16px;
  min-height: 65px;
  line-height: 1.2;
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px) {
  .home .about-us .about-us-image {
    height: 120px;
  }

  .home .about-us .about-us-image.image3 {
    height: 248px;
  }
}

@media only screen and (max-width: 960px) {
  .home .banner-video {
    height: calc(100vh - 56px);
  }

  .home .banner-video .banner-text {
    font-size: 30px;
  }

  .home .about-us,
  .home .what-we-host,
  .home .testimonial,
  .home .contact-us {
    padding: 50px 0;
  }

  .home .about-us .about-us-heading,
  .home .what-we-host .what-we-host-heading,
  .home .testimonial .testimonial-heading,
  .home .contact-us .contact-us-heading {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
  }

  .home .about-us .about-us-description {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
</style>
