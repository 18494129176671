<template>
  <v-container fluid>
    <v-card class="pt-10 secondary--text" color="transparent" flat>
      <v-row>
        <v-col cols="12">
          <v-row no-gutters justify="center" class="footer text-center">
            <v-col
              cols="12"
              lg="3"
              md="3"
              class="text-left mb-8 mb-lg-0 mb-md-0"
            >
              <div>
                <router-link
                  :to="{ name: 'HomeView' }"
                  class="secondary--text text-center text-lg-left text-md-left"
                  style="text-decoration: none"
                >
                  <h2>G.O.D</h2>
                </router-link>
              </div>
              <div
                class="social-icons text-center text-lg-left text-md-left"
                @click="goSocial"
              >
                <strong class="secondary--text cursor-pointer"
                  >Follow us on
                </strong>
                <v-btn icon large class="mr-lg-4 mr-md-4 cursor-pointer">
                  <v-icon color="secondary">mdi-instagram</v-icon>
                </v-btn>
                <div>
                  <b>Moksha Leisure's</b>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              lg="5"
              md="5"
              sm="6"
              class="text-left mb-8 mb-lg-0 mb-md-0"
            >
              <div class="mb-4 mb-lg-2 mb-md-2 secondary--text">
                <b>Quick Links</b>
              </div>
              <v-row>
                <v-col cols="5" lg="5" md="5">
                  <div class="footer-links">
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#about-us' }"
                        class="secondary--text"
                        style="text-decoration: none"
                        >About Us
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#what-we-host' }"
                        class="secondary--text"
                        style="text-decoration: none"
                        >What We Host
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#testimonial' }"
                        class="secondary--text"
                        style="text-decoration: none"
                        >Testimonial
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        exact
                        :to="{ name: 'HomeView', hash: '#contact-us' }"
                        class="secondary--text"
                        style="text-decoration: none"
                        >Contact us
                      </router-link>
                    </div>
                  </div>
                </v-col>
                <v-col cols="7" lg="5" md="5">
                  <div class="footer-links">
                    <div>
                      <router-link
                        class="secondary--text"
                        style="text-decoration: none"
                        :to="{ name: 'PolicyView', hash: '#terms' }"
                      >
                        Terms & Conditions
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        class="secondary--text"
                        style="text-decoration: none"
                        :to="{ name: 'PolicyView', hash: '#refund' }"
                      >
                        Refund Policy
                      </router-link>
                    </div>
                    <div>
                      <router-link
                        class="secondary--text"
                        style="text-decoration: none"
                        :to="{ name: 'PolicyView', hash: '#privacy' }"
                      >
                        Privacy Policy
                      </router-link>
                    </div>
                    <!-- <div>
                                            <router-link class="secondary--text" style="text-decoration: none"
                                                :to="{ name: 'AdminView' }">
                                                Admin Login</router-link>
                                        </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="6"
              class="text-left mb-8 mb-lg-0 mb-md-0"
            >
              <div class="mb-2">
                <b>Address</b>
              </div>
              <div>Moksha Leisure’s</div>
              <div>
                No 12, 60ft 1st main road, Shivanagar, Rajajinagar, Bengaluru
                560010
              </div>
              <br />
              <div class="text-left">
                Phone No:
                <a
                  class="secondary--text"
                  style="text-decoration: none"
                  href="tel:+918073129482"
                  >8073129482
                </a>
              </div>
              <div class="text-left">
                Mail us:
                <a
                  class="secondary--text"
                  style="text-decoration: none"
                  href="mailto:godcelebrationcafe@gmail.com"
                  >godcelebrationcafe@gmail.com
                </a>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="pt-0 pt-lg-3 pt-md-3 secondary--text">
                Copyright ©2024 All Rights Reserved | Garden of dreams Bangalore
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AppFooter",
  components: {},
  methods: {
    goSocial() {
      window.open("https://www.instagram.com/gardenofdreamsbengaluru");
    },
  },
};
</script>
<style scoped>
.footer-links > div {
  margin-bottom: 8px;
}
</style>
