import resourcesServer from "@/services/config/resourcesServerConfig";

export const getAllPlans = (locationId) => {
  return resourcesServer.get(`/booking/get-all-plans/${locationId}`);
};
export const getPlan = (planId,slotId) => {
  return resourcesServer.get(`/booking/get-plan/${planId}/${slotId}`);
};
export const viewSlots = (planId) => {
  return resourcesServer.get(`/booking/view-slots/${planId}`);
};
export const viewAvailableSlots = (data) => {
  return resourcesServer.post(`/booking/view-available-slots`, data);
};
export const makePayment = (data) => {
  return resourcesServer.post(`/booking/book-now`, data);
};
export const verifySignature = (data) => {
  return resourcesServer.post(`/booking/verify-signature`, data);
};
export const getAllBookings = () => {
  return resourcesServer.get("/booking/get-all-bookings");
};
export const sendMailConfirmation = (data) => {
  return resourcesServer.post("/booking/send-mail-confirmation", data);
};
export const getBookingByReferenceId = (data) => {
  return resourcesServer.post("/booking/get-booking-by-reference-id", data);
};
export const confirmBooking = (data) => {
  return resourcesServer.post("/booking/confirm-booking", data);
};
export const getAllLocations = () => {
  return resourcesServer.get("/booking/get-all-locations");
};
