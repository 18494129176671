<template>
  <v-app>
    <v-overlay :value="getSpinner" opacity="0.6" color="secondary">
      <v-progress-circular indeterminate color="primary" size="40">
      </v-progress-circular>
    </v-overlay>

    <v-app-bar app elevation="1" fixed color="primary" flat clipped-left>
      <v-app-bar-nav-icon
        color="secondary"
        class="d-lg-none d-md-none"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <app-header></app-header>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :value="drawer"
      class="navigation-drawer d-lg-none d-md-none primary"
      clipped
      touchless
      fixed
      disable-resize-watcher
    >
      <app-menu @closeDrawer="drawer = false" />
    </v-navigation-drawer>

    <v-main app ref="main" id="main" class="primary">
      <v-container fluid :class="isHomePage ? 'pa-0' : 'pa-3'">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
        <div class="whats-app-chat-bot cursor-pointer" @click="chatNow">
          <v-icon size="50" class="animate-icon pa-0" color="green"
            >mdi-whatsapp
          </v-icon>
        </div>
        <!-- <chat></chat> -->
      </v-container>
    </v-main>
    <v-footer color="primary">
      <app-footer></app-footer>
    </v-footer>
  </v-app>
</template>

<script>
import AppHeader from "@/components/Header.vue";
import AppFooter from "@/components/Footer.vue";
import AppMenu from "@/components/Menus.vue";
import { mapGetters } from "vuex";

// import chat from "./components/chat.vue";
export default {
  name: "app",
  watch: {
    "$route.name"(name) {
      if (name != "HomeView") {
        this.$vuetify.goTo(0, {
          duration: 650,
          offset: 0,
        });
      }
    },
  },
  data() {
    return {
      drawer: false,
      paymentDrawer: true,
    };
  },
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
    // chat
  },
  computed: {
    ...mapGetters(["getSpinner", "isMobile"]),
    isHomePage() {
      return ["HomeView"].includes(this.$route.name);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (window.innerWidth < 960) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    chatNow() {
      window.open(
        "https://api.whatsapp.com/send/?phone=918073129482&text&type=phone_number&app_absent=0"
      );
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style>
@import "./main.css";
</style>

<style>
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  scroll-behavior: smooth;
}

.whats-app-chat-bot {
  position: fixed;
  right: 10px;
  bottom: 10px;
  transform: translate(-10px, -10px);
  z-index: 9;
  margin: 0;
  border-radius: 50%;
}

.whats-app-chat-bot .animate-icon {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 128, 0, 1);
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.router-link-exact-active {
  border-bottom: 2px solid #9ab291;
}
</style>
